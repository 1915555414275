<template>
  <b-modal
    id="round-result-modal"
    ref="round-result-modal"
    no-close-on-backdrop
    hide-footer
    @show="loadTickets"
    size="xl"
    dialog-class="round-result-modal position-relative"
    body-class="p-0"
    header-class="align-items-center"
  >
    <template #modal-header="{ close }">
      <div class="d-flex">
        <h5 class="mb-0"><span class="text-primary">ตรวจรางวัล</span> ::: {{title}}</h5>
        <RoundStatus class="ml-2" :round="round" />
      </div>
      <b-button size="sm" variant="outline-danger" @click="close()">
        <i class="fas fa-times"></i>
      </b-button>
    </template>
    <div class="result-table position-relative">

      <table v-if="items.length && !isLoading" class="table-item table table-sm table-bordered mb-0 table-hover">
        <thead class="bg-info">
          <tr>
            <th colspan="2" width="100">เลขที่</th>
            <th width="170">วันที่</th>
            <th width="100">รายการ</th>
            <th width="100">ยอด</th>
            <th width="100">ส่วนลด</th>
            <th width="100">รวม</th>
            <th width="100">ถูกรางวัล</th>
            <th>หมายเหตุ</th>
            <!-- <th width="15%">สถานะ</th> -->
            <th width="50"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items"
            :key="item._id"
            :class="[
              {'alert-danger': item.status==='Canceled'},
              {'alert-warning': item.status==='Refund'}
            ]"
          >
            <td width="20" style="border-right: 0">
              <i class="fas fa-times text-danger" v-if="item.status==='Canceled'"></i>
              <i class="fas fa-undo text-warning fa-sm" v-if="item.status==='Refund'"></i>
            </td>
            <td width="80" class="text-center" style="border-left: 0">{{item.ticketNo}}</td>
            <td class="text-center">{{item.createdAt | dispDateTime("DD/MM/YYYY HH:mm:ss")}}</td>
            <td class="text-center">{{ item.summary.length }}</td>
            <td class="text-right text-info">{{ item.summary.amount | amountFormat }}</td>
            <td class="text-right text-danger">{{ item.summary.discount | amountFormat }}</td>
            <td class="text-right text-primary">{{ item.summary.credit | amountFormat }}</td>
            <td>
              <div v-if="item.isWon===true" class="text-success text-right">{{item.summary.won | amountFormat}}</div>
              <div v-else-if="item.isWon===false" class="text-danger text-center">ไม่ถูกรางวัล</div>
            </td>
            <td class="text-center">{{ item.remarks }}</td>
            <!-- <td class="text-center">
              <TicketStatus :status="item.status" />
            </td> -->
            <td class="text-center">
              <button class="btn btn-outline-info btn-sm" @click="viewTicket(item._id)"><i class="fas fa-list"></i></button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="3" class="text-center">รวม</th>
            <th class="text-center">{{ summary.length }}</th>
            <th class="text-right text-info">{{ summary.amount | amountFormat }}</th>
            <th class="text-right text-danger">{{ summary.discount | amountFormat }}</th>
            <th class="text-right text-primary">{{ summary.credit | amountFormat }}</th>
            <th class="text-right text-success">{{ summary.won | amountFormat }}</th>
            <th colspan="2"></th>
          </tr>
        </tfoot>
      </table>

      <div v-if="!items.length && !isLoading" class="alert alert-warning m-3 mb-0">ไม่มีรายการแทง</div>

      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        background-color="#EEEEEE"
        :height="60"
        :width="60"
        color="#51CE8D"
        loader="dots"
      />

      <ViewTicketModal :is-show="isShowTicketModal" :ticket-id="viewTicketId" @close="isShowTicketModal=false" />
    </div>
  </b-modal>
</template>
<script>
import ReportService from '@/services/ReportService'
import Swal from 'sweetalert2'
import moment from '@/helpers/moment'
import _ from 'lodash'
import RoundStatus from './RoundStatus'
import ViewTicketModal from '@/views/report/components/ViewTicketModal'
import cAlert from '@/helpers/alert'

export default {
  name: 'ViewRoundResultModal',
  props: ['isShow', 'roundId'],
  components: {
    RoundStatus,
    ViewTicketModal
  },
  data() {
    return {
      isLoading: false,
      data: null,
      isShowTicketModal: false,
      viewTicketId: null
    }
  },
  computed: {
    title() {
      if(!this.data || this.isLoading)
        return ''

      const roundDate = moment(this.data.round.roundDate.date).format("DD/MM/YYYY")
      return `[${this.data.round.note.groupTitle}] ${this.data.round.note.marketTitle} - ${roundDate}`
    },
    round() {
      if(!this.data || this.isLoading)
        return null

      return this.data.round
    },
    items() {
      return this.data?.tickets || []
    },
    summary() {
      return this.items.reduce((sum, item)=>{
        if(item.status !== 'Canceled' && item.status !== 'Refund') {
          sum.length += item.summary.length
          sum.amount += item.summary.amount
          sum.discount += item.summary.discount
          sum.credit += item.summary.credit

          if(item.isWon)
            sum.won += item.summary.won
        }

        return sum
      }, {
        length: 0,
        amount: 0,
        discount: 0,
        credit: 0,
        won: 0
      })
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.$refs['round-result-modal'].show()
      }else{
        this.$refs['round-result-modal'].hide()
      }
    }
  },
  methods: {
    loadTickets() {
      if(!this.roundId)
        return

      this.isLoading = true
      ReportService.getRoundTickets(this.roundId).then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        this.$refs['ticket-modal'].hide()
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'ดึงข้อมูลไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    viewTicket(ticketId) {
      this.isShowTicketModal = true
      this.viewTicketId = ticketId
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId === 'round-result-modal')
        this.$emit('close')
    })
  }
}
</script>
<style lang="scss" scoped>
.round-result-modal {
  .result-table {
    min-height: 80px;

    table {
      border-left: 0;
      border-right: 0;
      thead {
        tr {
          th {
            text-align: center;
            font-size: 90%;
            padding: 0.25rem;
          }

          th:first-child {
            border-left: 0;
          }
          th:last-child {
            border-right: 0;
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: 90%;
            vertical-align: middle;
            padding: 0.25rem;
          }

          td:first-child {
            border-left: 0;
          }
          td:last-child {
            border-right: 0;
          }
        }
      }
    }
  }
}
</style>
